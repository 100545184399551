<template>
  <div
    class="export-data-btn-container"
    id="exportDataButton"
  >
    <AdsButton
      class="export-data-btn"
      :hidden="hideExportDataBtn"
      buttonText="Export Data"
      outlined
      secondary
      v-if="!isMobileView()"
      @click="exportToCsv()"
    >
    </AdsButton>
  </div>
</template>

<script>
import { AdsButton } from "@nswdoe/doe-ui-core";
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';

export default {
  name: 'ExportDataBtn',
  components: {
    AdsButton,
  },
  data: function() {
    return {
      headers: [
        'additional_details.questions',
        'checkin_timestamp',
        'checkout_timestamp',
        'event_id',
        'event_source',
        'purpose',
        'venue.address',
        'venue.id',
        'venue.name',
        'venue.site',
        'venue.type',
        'venue_contact.name',
        'visitor.company_name',
        'visitor.id',
        'visitor.job_id',
        'visitor.name',
        'visitor.phone',
        'visitor.type',
        'visitor.site_safety_induction',
      ],
    }
  },
  props: {
    hideExportDataBtn: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    isMobileView() {
      return window.innerWidth < 960;
    },
    exportToCsv() {
      // initialise the header for csv
      //let csvContent = 'data:text/csv;charset=utf-8,' + this.headers.join(',') + '\r\n';
      const BOM = "\uFEFF";
      let csvContent = BOM + this.headers.join(',') + '\r\n';

      // parse each visitor records in the data table
      if (this.visitors.length > 0) {
        for (const visitor of this.visitors) {
          const row = [];

          for (const keyName of this.headers) {
            if (keyName === 'visitor.type') {
              const revertedType = this.revertVisitorType(visitor[keyName]);

              row.push(revertedType);
            } else if(keyName === 'checkin_timestamp' || keyName === 'checkout_timestamp') {
              row.push(visitor[keyName] ? this.getFormattedDate(visitor[keyName]) : '');
            } else {
              row.push(visitor[keyName] ? this.getFormattedValue(visitor[keyName]) : '');
            }
          }

          //const rowContent = row.join(',') + '\r\n';
          const rowContent = 
            row
            .map(String)  // convert every value to String
            .map(v => v.replaceAll('"', '""'))  // escape double quotes
            .map(v => `"${v}"`)  // quote it
            .join(',')  // comma-separated
            .concat('\r\n'); // rows starting on new lines
          csvContent += rowContent;
        }
      }

      //const encodedUri = encodeURI(csvContent);
      //window.open(encodedUri);
      var blob = new Blob([csvContent], {type: "text/csv;charset=utf-8"});
      saveAs(blob, "export.csv")
    },
    revertVisitorType(type) {
      if (!type) {
          return '';
      }

      switch (type) {
          case 'Parent/Carer':
              return 'parentCarer';
          case 'Volunteer/Parent volunteer':
              return 'volunteer';
          case 'Contractor':
              return 'contractor';
          case 'Contractor/Other':
              return 'contractorOther';
          case 'School staff/Other':
              return 'other';
          default:
              return type;
      }
    },
    getFormattedDate(value) {
      const d = new Date(value + (value.endsWith('Z') ? '' : 'Z'));
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return d.toLocaleString('en-AU', {timeZone: timeZone});
    },
    getFormattedValue(value) {
      let formattedValue = value;

      if (typeof value === 'object' && value !== null) {
        // escape the comma in the object
        const stringified = JSON.stringify(value);
        const regex = /"/g;
        formattedValue = `"${stringified.replace(regex, '""')}"`;
      } else if (String(formattedValue).includes(',')) {
        // escapge any comma in an ordinary string value
        formattedValue = `"${formattedValue}"`;
      }

      return formattedValue;
    }
  },
  computed: {
    ...mapGetters('visitors', [
      'visitors',
    ]),
  }
}
</script>

<style>
.export-data-btn {
  margin-left: 16px;
  min-height: 48px;
  align-self: end;
  font-weight: bold;
  /* margin-bottom: 17px; */
}
</style>
