const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const getTime = (dateObj) => {
    return dateObj.getHours().toString().padStart(2, '0') + ':'
        + dateObj.getMinutes().toString().padStart(2, '0');
};

const getUTCDateObj = (dateStr) => {
    if (!dateStr.endsWith('Z')) {
        return dayjs(dateStr.slice(0, 23) + 'Z').toDate();
    }

    return dayjs(dateStr).toDate();
}

const isForcedCheckout = (checkinTimeStr) => {
    // Local forced check-out time - 3:00AM
    const checkoutTime = dayjs().set('hour', 3).set('minute', 0).set('second', 0);
    const forcedCheckoutTime = checkoutTime.utc();

    if (!checkinTimeStr.endsWith('Z')) {
        checkinTimeStr = checkinTimeStr.slice(0, -7) + 'Z'
    }

    if (forcedCheckoutTime.diff(checkinTimeStr, 's') >= 0) {
        return true;
    }

    return false;
}

const getEntryRqmtsObj = (item) => {
    if (item.doe_entry_requirements) {
        if (item.doe_entry_requirements === 'Complete') {
        item['doe_entry_requirements_obj']['icon'] = 'check_circle';
        item['doe_entry_requirements_obj']['iconStyle'] = '';
        item['doe_entry_requirements_obj']['iconColor'] = '#00A908';
        item['doe_entry_requirements_obj']['value'] = 'Complete';
        }

        if (item.doe_entry_requirements === 'Not met') {
        item['doe_entry_requirements_obj']['icon'] = 'not_interested';
        item['doe_entry_requirements_obj']['iconStyle'] = 'outlined';
        item['doe_entry_requirements_obj']['iconColor'] = 'primary';
        item['doe_entry_requirements_obj']['value'] = 'Not met';
        }
    } else {
        item['doe_entry_requirements_obj']['icon'] = '';
        item['doe_entry_requirements_obj']['value'] = '';
    }
}

const convertVisitorType = (type) => {
    if (!type) {
        return '';
    }

    switch (type) {
        case 'parentCarer':
            return 'Parent/Carer';
        case 'volunteer':
            return 'Volunteer/Parent volunteer';
        case 'contractor':
            return 'Contractor';
        case 'contractorOther':
            return 'Contractor/Other';
        case 'other':
            return 'School staff/Other';
        default:
            return type; 
    }
}

const getFormattedRecord = (item) => {
    let timeIn = '';
    let timeOut = '';

    try {
        item["visitor.site_safety_induction"] ??= "false"
        const checkInDate = new Date(self.getUTCDateObj(item.checkin_timestamp));
        // convert dateObj to timestamp for sorting
        item['checkInDate'] = +dayjs(checkInDate);
        timeIn = self.getTime(checkInDate);

        if (item.checkout_timestamp) {
            const checkOutDate = new Date(self.getUTCDateObj(item.checkout_timestamp));
            item['checkOutDate'] = +dayjs(checkOutDate);
            timeOut = self.getTime(checkOutDate);
        } else {
            if (self.isForcedCheckout(item.checkin_timestamp)) {
                timeOut = 'Expired';
            }
        }
    } catch (error) {
        timeIn = '---';
    }

    // entry requirements handler
    // item['doe_entry_requirements_obj'] = {};
    // self.getEntryRqmtsObj(item);

    // visitor type converting
    item['visitor.type'] = self.convertVisitorType(item['visitor.type']);

    // item['q1Response'] = item['additional_details.covid_questions']
    // ? item['additional_details.covid_questions'][0].response
    // : '';
    item['timeIn'] = timeIn;
    item['timeOut'] = timeOut;

    return item;
}

const self = module.exports = {
    getTime,
    getUTCDateObj,
    isForcedCheckout,
    getEntryRqmtsObj,
    convertVisitorType,
    getFormattedRecord,
};