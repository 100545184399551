import { visitorsService } from '../../api/visitors.service';
import { getFormattedRecord } from '../helpers';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc);
dayjs.extend(timezone);

const state = () => ({
  items: [],
  filters: {
    dateFrom: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} 00:00:00`,
    dateTo: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} 23:59:59`,
    selectedVenueId: 0,
    visitorType: '',
    selectedInductionOption: '',
  },
  selectedSchoolName: '',
  loading: false,
  todayCheckinsCount: 0,
  errors: [],
  mobileModalOn: false,
  modalOn: false,
  evacuationMode: false,
  evacuationCount: 0,
  markEntryQueue: -1,
  selectedEventIds: [],
  storedEventIds: [],
  selectedEvents: [],
});

// getters
const getters = {
  visitors: state => {
    return state ? state.items : [];
  },
  dateFrom: state => {
    return state ? state.filters.dateFrom : '';
  },
  dateTo: state => {
    return state ? state.filters.dateTo : '';
  },
  selectedVenueId: state => {
    return state ? state.filters.selectedVenueId : 0;
  },
  isLoading: state => {
    return state ? state.loading : false;
  },
  visitorType: state => {
    return state ? state.filters.visitorType : '';
  },
  selectedInductionOption: state => {
    return state ? state.filters.selectedInductionOption : '';
  },
  todayCheckinsCount: state => {
    return state ? state.todayCheckinsCount : 0;
  },
  errors: state => {
    return state ? state.errors : [];
  },
  mobileModalOn: state => {
    return state ? state.mobileModalOn : false;
  },
  modalOn: state => {
    return state ? state.modalOn : false;
  },
  evacuationMode: state => {
    return state ? state.evacuationMode : false;
  },
  evacuationCount: state => {
    return state ? state.evacuationCount : 0;
  },
  selectedSchoolName: state => {
    return state ? state.selectedSchoolName : '';
  },
  markEntryQueue: state => {
    return state ? state.markEntryQueue : -1;
  },
  selectedEventIds: state => {
    return state ? state.selectedEventIds : [];
  },
  storedEventIds: state => {
    return state ? state.storedEventIds : [];
  },
  selectedEvents: state => {
    return state ? state.selectedEvents : [];
  },
};
// actions
const actions = {
  setFilters({ commit, dispatch }, { selectedVenueId, dateFrom, dateTo, visitorType, selectedInductionOption }) {
    commit('setFilters', { selectedVenueId, dateFrom, dateTo, visitorType, selectedInductionOption });
    dispatch('setVisitors');
  },
  setVisitors({ state, commit }) {
    commit('setLoading', true);

    visitorsService
      .get(
        state.filters.selectedVenueId,
        state.filters.dateFrom,
        state.filters.dateTo,
        state.filters.visitorType,
        dayjs.tz.guess(),
      )
      .then(response => {
        response.map((item) => {
          return getFormattedRecord(item);
        });

        response = response.filter((item) => {
          if (state.filters.selectedInductionOption === '') {
            return true;
          } else {
            return String(item['visitor.site_safety_induction']) === state.filters.selectedInductionOption;
          }
        });

        // evacuation list filter
        if (state.evacuationMode) {
          response = response.filter((item) => {
            return !item['timeOut'];
          });
          
          commit('setEvacuationCount', response.length);
        }

        commit('setItems', response);

        commit('setLoading', false);
      })
      .catch((error) => {
        commit('setErrors', error);

        commit('setItems', []);

        commit('setLoading', false);
      });
  },
  setTodayCheckinsCount({ commit }, number) {
    commit('setTodayCheckinsCount', number);
  },
  async checkOutVisitors({ commit }, { venueId, events }) {
    try {
      visitorsService.checkOut(venueId, events);
    }catch(error) {
      commit('setErrors', error);
    }
  },
  setMobileModalOn({ commit }, val) {
    if (!val) {
      commit('setMobileModalOn', false);
    } else {
      commit('setMobileModalOn', true);
    }
  },
  setModalOn({ commit }, val) {
    if (!val) {
      commit('setModalOn', false);
    } else {
      commit('setModalOn', true);
    }
  },
  setEvacuationMode({ commit }, val) {
    if (!val) {
      commit('setEvacuationMode', false);
    } else {
      commit('setEvacuationMode', true);
    }
  },
  setEvacuationCount({ commit }, number) {
    commit('setEvacuationCount', number);
  },
  setSelectedSchoolName({ commit }, name) {
    commit('setSelectedSchoolName', name);
  },
  async markEntry({ commit, getters }, {item, val}) {
    let updatedItem;

    try {
      updatedItem = await visitorsService.markEntry(item['venue.id'], item['event_id'], val);
    }catch(error) {
      commit('setErrors', error);
    }

    if (!updatedItem) {
      return;
    }

    // update queue
    const currentQ = getters.markEntryQueue;

    commit('setMarkEntryQueue', currentQ - 1);

    updatedItem['doe_entry_requirements_obj'] = {};
    
    if (updatedItem.doe_entry_requirements === 'Complete') {
      updatedItem['doe_entry_requirements_obj']['icon'] = 'check_circle';
      updatedItem['doe_entry_requirements_obj']['iconStyle'] = '';
      updatedItem['doe_entry_requirements_obj']['iconColor'] = '#00A908';
      updatedItem['doe_entry_requirements_obj']['value'] = 'Complete';
    } else if (updatedItem.doe_entry_requirements === 'Not met'){
      updatedItem['doe_entry_requirements_obj']['icon'] = 'not_interested';
      updatedItem['doe_entry_requirements_obj']['iconStyle'] = 'outlined';
      updatedItem['doe_entry_requirements_obj']['iconColor'] = 'primary';
      updatedItem['doe_entry_requirements_obj']['value'] = 'Not met';
    }

    commit('updateItem', updatedItem);
  },
  setMarkEntryQueue({ commit }, number) {
    commit('setMarkEntryQueue', number);
  },
  updateDataTable({ commit, getters }, record) {
    const records = getters.visitors;
    // check if record exists
    const existingRecord = records.filter((item) => {
      return item['event_id'] == record['event_id'];
    });

    if (existingRecord.length > 0) {
      // get formatted record
      const formattedRecord = getFormattedRecord(record);

      commit('updateItem', formattedRecord);
    } else {
      // get formatted record
      const formattedRecord = getFormattedRecord(record);

      commit('addVisitor', formattedRecord);
    }
  },
  setSelectedEventIds({ commit }, idArray) {
    commit('setSelectedEventIds', idArray);
  },
  setStoredEventIds({ commit }, idArray) {
    commit('setStoredEventIds', idArray);
  },
  setSelectedEvents({ commit }, events) {
    commit('setSelectedEvents', events);
  },
};
// mutations
const mutations = {
  setFilters(state, { selectedVenueId, dateFrom, dateTo, visitorType, selectedInductionOption }) {
    if (selectedVenueId && selectedVenueId > 0) {
      state.filters.selectedVenueId = selectedVenueId ? selectedVenueId : 0;
    }

    if (dateFrom) {
      state.filters.dateFrom = dateFrom;
    }

    if (dateTo) {
      state.filters.dateTo = dateTo;
    }

    if (visitorType) {
      state.filters.visitorType = visitorType;
    } else {
      state.filters.visitorType = '';
    }

    if (selectedInductionOption) {
      state.filters.selectedInductionOption = selectedInductionOption;
    } else {
      state.filters.selectedInductionOption = '';
    }
  },
  setItems(state, items) {
    //Steve Citizen    
    state.items = items;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setTodayCheckinsCount(state, number) {
    state.todayCheckinsCount = number;
  },
  updateItem(state, payload) {
    state.items.map(item => {
      if (item.event_id === payload.event_id) {
        for (const property in payload) {
          item[property] = payload[property];
        }

        return item;
      }
    });
  },
  setErrors(state, error) {
    state.errors.push(error);
  },
  setMobileModalOn(state, val) {
    state.mobileModalOn = val;
  },
  setModalOn(state, val) {
    state.modalOn = val;
  },
  setEvacuationMode(state, val) {
    state.evacuationMode = val;
  },
  setEvacuationCount(state, number) {
    state.evacuationCount = number;
  },
  setSelectedSchoolName(state, name) {
    state.selectedSchoolName = name;
  },
  setMarkEntryQueue(state, number) {
    state.markEntryQueue = number;
  },
  addVisitor(state, visitorObj) {
    state.items.unshift(visitorObj);
  },
  setSelectedEventIds(state, idArray) {
    state.selectedEventIds = idArray;
  },
  setStoredEventIds(state, idArray) {
    state.storedEventIds = idArray;
  },
  setSelectedEvents(state, events) {
    state.selectedEvents = events;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
