<template>
  <v-app>
    <AppBar
      v-if="$OAuth.data.profile"
      id="appHeader"
      color="primary"
      appName="school-visitor-checkin"
      :searchFilterMutator="false"
      :showFullScreen="false"
      :showNavIcon="false"
      :enableHomeLink="false"
      style="min-height: 64px;"
    >
      <template slot="appIcon">
        <AppIcon
          appName="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>

      <template slot="title">
        <div class="app-bar-title">School Visitor Check-in</div>
      </template>

      <template slot="dataSwitcher" v-if="schools && !$vuetify.breakpoint.xs">
        <label for="app-bar--school-select" hidden>
          Select a school
        </label>
        <DataSwitcher
          id="app-bar--school-select"
          v-model="selectedSchool"
          :items="schools"
          placeholder="Select a school"
          item-value="id"
          item-text="name"
          v-on:change="onChange"          
          :clearable="false"
          prepend-inner-icon="none"
          style="width:350px"
          :readonly="evacuationMode"
        >
          <template v-slot:item="data">
            <span>{{ `${data.item.name}(${data.item.id.toString().slice(0, 4)})`}}</span>
          </template>
        </DataSwitcher>
      </template>

      <template slot="myEssentials"></template>

      <template slot="profile">
        <Profile
          :givenName="profile.given_name"
          :surname="profile.family_name"
          :jobTitle="roles"
          :updateProfile="false"
          :changePassword="false"
          :updateSecretQuestion="false"
          :portalSettings="false"
          :logout="true"
          :logoutHandler="logOut"
        />
      </template>
    </AppBar>

    <!-- School select mobile view-->
    <v-container 
      v-if="schools && $vuetify.breakpoint.xs && $OAuth.data.profile && !mobileModalOn" 
      class="mt-14"
      id="schoolSelectMobile"
    >
        <label for="mobile-select-schoool-label" hidden>
          Select a school
        </label>
      <DataSwitcher
        id="mobile-select-schoool-label"
        v-model="selectedSchool"
        :items="schools"
        placeholder="Select a school"
        item-value="id"
        item-text="name"
        v-on:change="onChange"          
        :clearable="false"
        :readonly="evacuationMode"
        prepend-inner-icon="none"
        dark
      >
        <template v-slot:item="data">
          <span>{{ `${data.item.name}(${data.item.id.toString().slice(0, 4)})`}}</span>
        </template>
      </DataSwitcher>
    </v-container>

    <Banner
      v-if="!$vuetify.breakpoint.xs && $OAuth.data.profile"
      :sticky="sticky"
      id="appBanner"
      style="flex: 0 1 auto; margin-top:64px"
    >
      <template slot='content'>
        <div class="d-flex align-start flex-wrap banner-inner-wrap">
          <div class="pt-1">
            <v-icon
              alt=""
              :size="50"
              class="material-icons-outlined"
              >
              playlist_add_check
            </v-icon>
          </div>

          <div class="d-flex flex-column">
              <h1 class="appTitle">
                Logbook
              </h1>
              <p class="appSubtitle">
                Check-in records at the school
              </p>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex">
            <v-card
              elevation="2"
              class="checkin-count"
              :color="evacuationMode? '#FFF' : '#1A3B69'"
            >
            <v-card-title 
              class="justify-center text-h4"
              :class="evacuationMode ? 'red--text' : 'white--text'"
              >
              {{ evacuationMode ? evacuationCount : todayCheckinsCount }}
            </v-card-title>

            <v-card-subtitle 
              class=""
              :class="evacuationMode ? 'red--text' : 'white--text'"
            >
              {{ evacuationMode ? 'visitors currently on-site' : 'check-ins today' }}
            </v-card-subtitle>
            </v-card>
          </div>
        </div>
      </template>
    </Banner>

    <!-- content body -->
    <v-content class="mt-0 pt-0 v-main" id="main" aria-label="School Visitor Checkin">
      <v-container class="pa-0" fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-content>

    <!----------- OAuth Failure Alert -------------->
    <v-container>
      <Alert
        :showAlert="oAuthFailed"
        type="warning"
        text="Unauthorised"
        subtext="Authentication failed"
        actionText="Confirm"
        :actionHandler="logOut"
        position="top"
        border="top"
      >
      </Alert>
    </v-container>

    <!----------- Websocket Failure Alert -------------->
    <v-container>
      <Alert
        :showAlert="socketFailed"
        type="error"
        icon="alarm"
        text="Your session has expired. Please log in again to continue"
        subtext=""
        actionText="LOGIN TO CONTINUE"
        :actionHandler="refreshPage"
        position="top"
        border="top"
      >
      </Alert>
    </v-container>

    <!----------- Session Pre-expiry Warning Alert -------------->
    <v-container class="session-warning-alert" v-if="showCountdown">
      <Alert
        :showAlert="showCountdown"
        type="warning"
        icon="alarm"
        text=""
        :htmlText="`<p style='font-weight:normal; margin-bottom:0;'>Your session will expire in <b>${sessionCountdown}</b></p>`"
        actionText="CONTINUE SESSION"
        :actionHandler="refreshToken"
        position="top"
        border="top"
      >
      </Alert>
    </v-container>

    <!-- Google Analytics Variables -->
    <div id="googleVariableLocation" class="google-variable">{{ profile ? profile.locations : '' }}</div>
    <div id="googleVariableRole" class="google-variable">{{ roles || '' }}</div>

    <!-- Help Button -->
    <help-btn
      :hideHelpBtn="hideHelpBtn"
    ></help-btn>
  </v-app>
</template>

<script>
import dayjs from 'dayjs';
// store
import { mapGetters, mapActions, mapState } from 'vuex';
// components
import {
  AppBar,
  Profile,
  DataSwitcher,
  AppIcon,
  Banner,
  Alert,
} from '@nswdoe/doe-ui-core';
import { oauthService } from './api/oauth.service';
import HelpBtn from './components/HelpBtn.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    Profile,
    DataSwitcher,
    AppIcon,
    Banner,
    Alert,
    HelpBtn,
  },
  data() {
    return {
      sticky:true,
      items: [
        {
          title: 'Visitors List',
          icon: 'laptop',
          iconOutlined: false,
          route: '/',
          clickHandler: () => {},
        },
      ],
      selectedSchool: null,
      schoolDataLoaded: false,
      oAuthFailed: false,
      socket: null,
      socketRetry: 0,
      socketFailed: false,
      isTodayCovered: true,
      showCountdown: false,
      sessionCountdown: '',
      countdown: null,
      timeout: null,
      hideHelpBtn: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // A hacky way to alert users when OAuth fails
      // TODO - remove the hacky way once ADS OAuth errors can be passed down to the app
      if (this.profile.given_name === 'unknown' && this.profile.family_name === 'unknown') {
        setTimeout(() => {
          if (this.profile.given_name === 'unknown' && this.profile.family_name === 'unknown') {
            this.oAuthFailed = true;
          }
        }, 3000);
      }

      // reposition the help button
      if (window.innerWidth >= 600) {
        setTimeout(() => {
          const schoolDropdown = this.$el.querySelector('#dataSwitcherContainer');
          const p = schoolDropdown.parentNode;
          const helpBtn = this.$el.querySelector('#helpButton').firstChild;

          p.insertBefore(helpBtn, schoolDropdown);

          this.hideHelpBtn = false;
        }, 800);
      }

      // VCI-153 - fix profile menu on mobile view
      if (window.innerWidth < 600) {
        setTimeout(() => {
          const roleText = this.$el.querySelector('.profile-subtitle-font');

          if (roleText) {
            roleText.classList.remove('profile-subtitle-font');
            roleText.style.fontSize = '0.6em';
          }
        }, 800);
      }
    });
  },
  computed: {
    profile() {
      this.initVenues();
      return this.$OAuth.data.profile
        ? this.$OAuth.data.profile
        : {
            firstName: 'unknown',
            lastName: 'unknown',
          };
    },
    roles() {
      return this.$OAuth.data.profile
        ? typeof this.$OAuth.data.profile.det_roles === 'string'
          ? this.$OAuth.data.profile.det_roles
          : this.$OAuth.data.profile.det_roles.join(',')
        : '';
    },
    ...mapState('visitors', [
        'todayCheckinsCount', 
        'mobileModalOn',
        'evacuationMode',
        'evacuationCount',
        'selectedSchoolName',
      ]),
    ...mapGetters('schools', {
      schools: 'schools',
    }),
    ...mapGetters('visitors', [
      'selectedVenueId',
      'dateTo',
      'visitorType'
    ]),
  },
  watch: {
    schools: function() {
      if (!this.schools || (this.schools.length <= 0)) {
        this.$router.push('/unauthorized');
      }
    },
    selectedVenueId() {
      if (this.$OAuth.data.accessToken) {
        // close existing socket
        if (this.socket) {
          this.socket.close();
        }

        this.socket = this.constructWebsocket(
          this.$OAuth.data.idToken, 
          this.selectedVenueId
        );
      } else {
        alert('Websocket initialisation error...') //todo - optimise alert
      }
    },
    socket() {
      if (!this.socket) {
        alert('Sokect is gone...')  //todo - optimise alert
      }
    },
    dateTo() {
      const today = dayjs().startOf('day');
      const filterDateTo = dayjs(this.dateTo);

      this.isTodayCovered = filterDateTo >= today;
    },
    roles() {
      if (this.roles) {
        // initialise session countdown
        const distance = this.getDistance();
        
        this.timeout = setTimeout(() => {
          this.setCountdown(5 * 60); // 5 mins to expiry
          this.showCountdown = true;
        }, distance);
      }
    },
  },
  methods: {
    onChange() {
      this.setFilters({selectedVenueId: this.selectedSchool});

      // find selected shool name
      for (let i = 0; i < this.schools.length; i++) {
        if (this.schools[i]['id'] === parseInt(this.selectedSchool)) {
          this.setSelectedSchoolName(this.schools[i]['name'].trim());
          
          return;
        }
      }
    },
    constructWebsocket(idToken, selectedVenueId) {
      // establish websocket connection
      const socket = new WebSocket(process.env.VUE_APP_WS_URL + 
        `?token=${idToken}` +
        `&schoolCode=${selectedVenueId}`);
      socket.onopen = function() {
        // console.log('WebSocket is open.') //todo - remove
      };
      socket.onerror = () => {
        // console.log('WebSocket error observed...' + JSON.stringify(event)) // todo - remove
        this.showCountdown = false;
        this.socketFailed = true;
      };
      socket.onclose = () => {
        // console.log("WebSocket is closed.");  // todo - remove
        const currentIdToken = window.localStorage.getItem('id_token');

        // reconnect if venueId is still the same, max retry 20 times
        if (this.selectedVenueId === selectedVenueId && this.socketRetry < 19) {
          this.constructWebsocket(currentIdToken, selectedVenueId);
          this.socketRetry++;
        }
      };
      socket.onmessage = (event) => {
          // console.log('Message from server ', event.data); // todo - remove
          let data;

          try {
            data = JSON.parse(event.data);
          } catch (e) {
            alert('Invalid check-in record')  //todo - optimise alert
          }

          if (data['event_id'] && this.isTodayCovered) {
            // update records if the types match or under evacuation mode
            if (!this.visitorType || data['visitor.type'].includes(this.visitorType) || this.evacuationMode ) {
              this.updateDataTable(data);
            }
          }
      };

      return socket;
    },
    initVenues() {
      // VCI-119 venues is based on the VDL group only
      if (this.$OAuth.data.profile && !this.schoolDataLoaded) {        
        this.schoolDataLoaded = true;
        let venues = [];

        // grab locations from groups
        if (this.$OAuth.data.profile.groups.length > 0) {
          this.$OAuth.data.profile.groups.map(group => {
            const schoolCode = group.slice(4, 8);
            const groupName = group.slice(8);

            if (groupName === 'VDL') {
              venues.push(schoolCode);
            }
          });
        }

        this.setVenues(venues).then(()=>{
          this.selectedSchool = this.selectedVenueId;
        });
      }
    },
    flatDeep(arr, d = 1) {
      return d > 0
        ? arr.reduce(
            (acc, val) =>
              acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val),
            []
          )
        : arr.slice();
    },
    logOut() {
      this.$OAuth.logout();
    },
    refreshPage() {
      window.location.reload();
    },
    refreshToken() {
      try{
        oauthService.refreshToken(window.localStorage.getItem('refresh_token'))
        .then((val) => {
          const { access_token, id_token, refresh_token, token_type } = val;
          
          this.$OAuth.setToken(access_token, id_token, token_type, refresh_token);

          // reset session countdown
          clearInterval(this.countdown);
          clearTimeout(this.timeout);
          this.showCountdown = false;
          const distance = this.getDistance();
        
          this.timeout = setTimeout(() => {
            this.setCountdown(5 * 60); // 5 mins to expiry
            this.showCountdown = true;
          }, distance);
        })
      } catch(e) {
        this.showCountdown = false;
        this.socketFailed = true;
      }
    },
    setCountdown(duration) {
      let timer = duration, minutes, seconds;
      
      this.countdown = setInterval(() => {
          minutes = parseInt(timer / 60, 10)
          seconds = parseInt(timer % 60, 10);
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          this.sessionCountdown = minutes + ":" + seconds + ' minutes';

          if (--timer < 0) {
            // show session expiry
            this.showCountdown = false;
            this.socketFailed = true;

            clearInterval(this.countdown);
          }
      }, 1000);
    },
    getDistance() {
      const expiryTimestamp = window.localStorage.getItem('token_expiry')
      const now = Date.now();
      const expiryTime = new Date(expiryTimestamp*1000);
      
      return expiryTime - now - (5 * 60000);
    },
    ...mapActions('schools', ['setVenues']),
    ...mapActions('visitors', [
      'setFilters',
      'setVisitors',
      'setSelectedSchoolName',
      'updateDataTable',
    ]),
  },
};
</script>

<style scoped>
.v-application {
  background-color: #F4F4F7;
}
.banner-inner-wrap{
  padding: 0 45px 0 45px;
}
#schoolSelectMobile{
  background: #0A1F40;
}
.checkin-count{
  width: 12rem;
  text-align: center;
}
.google-variable {
  display: none;
}
#app-bar--select-schoool-label {
  display: none;
}
.app-bar-title {
  font-weight: 700;
  font-size: 1.25rem;
}
</style>
