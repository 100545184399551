<template>
  <div 
    class="checkout-btn-container"
  >
    <AdsButton
      :compact="true"
      :disabled="bulkPrintDisabled"
      buttonText="Print Label"
      @click="printLabel"
    ></AdsButton>
  </div>
</template>

<script>
import { AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: 'CheckoutBtn',
  components: {
    AdsButton,
  },
  props: {
    bulkPrintDisabled: {
      type: Boolean,
      default: false,
    },
    selectedRecords: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    printLabel() {
      // untick the selected records
      this.$emit('actionFired', 'printLabel');
      
      const routeData = this.$router.resolve({ name: 'print' });
        // store data
        sessionStorage.setItem('selectedRecords', JSON.stringify(this.selectedRecords));

        window.open(routeData.href, '_blank');
    },
  },
}
</script>

<style>
</style>