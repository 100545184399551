<template>
  <Alert
    :showAlert="checkoutFailed"
    type="warning"
    :htmlText="errorMsg"
    subtext="Please make sure you are not trying to check out the expired records. Otherwise, reach out for help if you constantly see this error message."
    position="top"
    border="top"
    actionText="Confirm"
    :actionHandler="closeAlert"
  ></Alert>
</template>

<script>
import { Alert } from "@nswdoe/doe-ui-core";
import { mapGetters } from 'vuex';

export default {
  name: 'CheckoutFailure',
  components: {
    Alert,
  },
  props: {
    checkoutConfirming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      failedRecrods: '',
      failedCheckouts: [],
      checkoutFailed: false,
    }
  },
  methods: {
    closeAlert() {
      this.checkoutFailed = false;
    },
    resetStatus() {
      this.$emit('checkoutAlertReset');
      this.failedCheckouts = [];
    },
    listFailedRecrods(checkouts) {
      for (let index = 0; index < checkouts.length; index++) {
        this.failedRecrods += `<li>${checkouts[index]['visitor.name']}</li>`;
      }
    }
  },
  watch: {
    checkoutConfirming() {
      if (this.checkoutConfirming) {
        // empty failedCheckouts and failedRecrods
        this.failedCheckouts = [];
        this.failedRecrods = '';

        // confirm check-out results
        this.visitors.map((visitor) => {
          this.storedEventIds.map((eventId) => {
            if (eventId === visitor.event_id) {
              if (!visitor.checkout_timestamp) {
                this.failedCheckouts.push(visitor);
              }
            }
          });
        });

        // construct error msg
        this.listFailedRecrods(this.failedCheckouts);

        // set flag of checkoutFailed
        this.checkoutFailed = this.failedCheckouts.length > 0 ? true : false;

        // reset
        this.resetStatus();
      }
    },
  },
  computed: {
    errorMsg() {
      return `<h3>The following check-out(s) failed:</h3> 
              <ul>${this.failedRecrods}</ul>`
    },
    ...mapGetters('visitors', [
      'visitors',
      'storedEventIds',
    ]),
  }
}
</script>

<style>

</style>
