import schoolMock from "./schools.json";

export const schoolService = {
  get
};

async function get(locations) {
  // TODO: School info must be fetched from School Open API
  let schools = [];

  // system admin
  if (locations.indexOf('9999') > -1) {
    schools = schoolMock.schools;
  } else {
    schools = schoolMock.schools.filter(school => locations.indexOf(school.schoolCode.toString()) > -1);
  }

  return schools.map(school => {
    return {
      id: school.siteId ? +`${school.schoolCode}${school.siteId}` : school.schoolCode,
      name: school.preferredName ? school.preferredName : school.schoolFullName,
    };
  });
}
// async function getToken() {
//   const params = new URLSearchParams();
//   params.append("grant_type", "client_credentials");
//   params.append("client_id", `${process.env.VUE_APP_SHCOOLE_API_CLIENT_ID}`);
//   params.append(
//     "client_secret",
//     `${process.env.VUE_APP_SHCOOLE_API_CLIENT_SECRET}`
//   ); //
//   params.append("scope=api/access app", "domapi:srdschool/access");

//   const header = {
//     Accept: "application/x-www-form-urlencoded",
//     "Cache-Control": "no-cache"
//   };
//   const url = `${process.env.VUE_APP_SHCOOLE_API_AUTH_ENDPOINT}`;
//   return datacontext.post(url, params, header);
// }
// async function getSchoolList(token) {
//   const params = new URLSearchParams();
//    params.append("x-correlation-id", uuid.v4());

//   const header = {
//     "Accept": "application/json",
//     // "Content-Type": "application/x-www-form-urlencoded"
//     "Cache-Control": "no-cache",
//     "x-correlation-id": uuid.v4(),
//     "Authorization": `Bearer ${token}`,
//     "Access-Control-Allow-Origin": "*",
//     'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
//   };
//   // "schoolCodes" : ["8232"]
//   const url = `${process.env.VUE_APP_SHCOOLE_API_ENDPOINT}`;
//   return datacontext.post(url, params, header);
// }
